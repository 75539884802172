











































































import Vue from 'vue';
import VueCookies from 'vue-cookies'
import { setFeedback } from '../store/actions';
import Trap from 'vue-focus-lock';

Vue.use(VueCookies);

interface IError {
	email: string;
	comment: string;
	gdpr: string;
}

interface FeedbackFieldData {
	fakeField1: string,
	fakeField2: string,
	timeoutFakeField3: string,
	email: string,
	comment: string,
	errors: IError,
	approveGdpr: boolean,
}

interface FeedbackFieldComputed {
	hasError: boolean,
	gdprPage: string,
	gdprText: string,
}

interface FeedbackFieldMethods {
	sendFeedback(): void;
	closeModal(event: Event): void;
	setEmailCookie(): void;
}

export default Vue.extend<FeedbackFieldData, FeedbackFieldMethods, FeedbackFieldComputed, {}>({
	name: 'FeedbackField',

	data() {
		return {
			fakeField1: '',
			fakeField2: '',
			timeoutFakeField3: '',
			email: '',
			comment: '',
			errors: {
				email: '',
				comment: '',
				gdpr: '',
			},
			approveGdpr: false,
		}
	},

	computed: {
		 hasError() {
      		return Object.values(this.errors).some((item) => item !== "");
    	},
		gdprPage() { return this.$store.state.gdprPage; },
		gdprText() { return this.$store.state.gdprText; },
	},

	methods: {
		sendFeedback() {
			const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			if(regexp.test(this.email)){
				this.errors.email = '';
			} else {
				this.errors.email = 'Obligatoriskt fält'
			}
			this.approveGdpr ? this.errors.gdpr = '' :  this.errors.gdpr = 'Obligatoriskt fält';
			this.comment !== '' ? this.errors.comment = '' : this.errors.comment = "Obligatoriskt fält";

			if(!this.hasError) {
				if(this.fakeField1 === '' && this.fakeField2 === '' && this.timeoutFakeField3 === '8bnwZQOjT80m1YIqKA10pQWaL61hyRGXV') {
					this.$cookies.remove('esw-feedback-email');
					this.$store.dispatch(setFeedback({ email: this.email, comment :this.comment }));
					this.$emit('close');
				} else {
					this.$emit('close');
				}
			}
		},
		closeModal(event) {
			var target = event.target as Node;
            var content = this.$refs.content as Node;
			if(content && !content.contains(target)){
				this.$emit('close');
			}
		},
		setEmailCookie(){
			this.$cookies.remove('esw-feedback-email');
			this.$cookies.set('esw-feedback-email', this.email, '1d');
		}
	},
	components: {
		Trap
	},
	mounted() {
		if(this.$cookies.isKey('esw-feedback-email')){
			this.email = this.$cookies.get('esw-feedback-email');
		}

		setTimeout(() => {
			this.timeoutFakeField3 = '8bnwZQOjT80m1YIqKA10pQWaL61hyRGXV';
		}, 1000)
	}
})
