










import Vue from 'vue';
import FeedbackField from './components/FeedbackField.vue';
import FeedbackMessage from './components/FeedbackMessage.vue';

// Interfaces for component typechecking!
interface AppData {
	show: boolean;
}

interface AppMethods {

}

interface AppComputed {
	showMessage: boolean;
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	data() {
		return {
			show: false,
		}
	},

	computed: {
		showMessage() {
			return this.$store.state.showMessage;
		}
	},

	methods: {
		onCloseMessage() {
			this.$store.commit({
				type:'setShowMessage',
				payload: false,
			});

			let body = document.querySelector('body') as HTMLElement;
			body.classList.remove('feedback-modal-open');
		},
		onOpenFeedbackModal() {
			this.show = true;
			let body = document.querySelector('body') as HTMLElement;
			body.classList.add('feedback-modal-open');
		},
		onCloseFeedbackModal() {
			this.show = false;
			let body = document.querySelector('body') as HTMLElement;
			body.classList.remove('feedback-modal-open');
		}
	},
	components: {
		FeedbackField,
		FeedbackMessage
	}
})
