





















import Vue from 'vue';
import Trap from 'vue-focus-lock';

interface IFeedbackMessageComputed {
  message: string
}

interface FeedbackMessagedMethods {
	closeModal(event: Event): void;
}

export default Vue.extend<{}, FeedbackMessagedMethods, IFeedbackMessageComputed, {}>({
  name: 'FeedbackMessage',

  computed: {
    message() {
      return this.$store.state.message;
    },
  },
  methods: {
    closeModal(event) {
      var target = event.target as Node;
      var content = this.$refs.content as Node;
      if(content && !content.contains(target)){
        this.$emit('closeMessage');
      }
    }
  },

  components: {
    Trap
  },
})
