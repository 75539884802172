import { MutationTree, Mutation } from 'vuex';
import { IState } from './state';
import { ActionPayload } from './actions';

type MutationHandler<PayloadType> = (state: IState, payload: ActionPayload<PayloadType>) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload);
}

const setShowMessageMutation: MutationHandler<boolean> = (state, { payload }) => {
	state.showMessage = payload
}

const setMessageMutation: MutationHandler<string> = (state, { payload }) => {
	state.message = payload
}


const mutations: MutationTree<IState> = {
	setState: setStateMutation,
	setShowMessage: setShowMessageMutation,
	setMessage: setMessageMutation
}

export default mutations
