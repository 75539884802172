import { IState } from "./state";
import { ActionTree, Store, ActionContext } from 'vuex';
import * as api from '../api/index';

type PayloadType = 'initApp' | 'setState' | 'setFeedback' | 'setShowMessage' | 'setMessage';

export interface IData {
	email: string,
	comment: string,
}

export type ActionPayload<ActionData> = {
	type: PayloadType,
	payload: ActionData
}

type ActionHandler<ActionData> = (this: Store<IState>, injectee: ActionContext<IState, IState>, payload: ActionPayload<ActionData>) => any;
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>;


// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
	type: 'initApp',
	payload: state
})

export const setFeedback: ActionCreator<IData> = (payload) => ({
	type: 'setFeedback',
	payload
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}

const setFeedbackAction: ActionHandler<IData> = ({ commit, state }, { payload }) => {
	let email = payload.email;
	let comment = payload.comment;
	let url = state.url;
	api.feedback(url, email, comment)
	.then((res) => {
		commit({
			type: 'setMessage',
			payload: res
		})

		commit({
			type: 'setShowMessage',
			payload: true,
		})

		let body = document.querySelector('body') as HTMLElement;
		body.classList.add('feedback-modal-open');
	})
	// Do api call
}

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	setFeedback: setFeedbackAction,
}

export default actions;
