export interface IState {
	showMessage: boolean,
	message: string,
	url: string,
	gdprPage: string,
	gdprText: string,
}

const deafultState: IState = {
	showMessage: false,
	message: '',
	url: '',
	gdprPage: '',
	gdprText: '',
}

export default deafultState;
