import axios from 'axios';
import qs from 'query-string';

const SitevisionHeader = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept': 'application/json',
}

const BASE_URL = '';

export const feedback = (url: string, email: string, comment: string): Promise<any> =>
  axios.post<any>(`${BASE_URL}${url}`, qs.stringify({ email, comment }),{ headers: { ...SitevisionHeader } })
    .then(response => {
      return response.data.mesg;
    }).catch(mesg => {
      console.error('ERROR: ' + JSON.stringify(mesg));
    })